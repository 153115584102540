import React from 'react';
import {Row, Col, Table} from 'react-bootstrap';
import Layout from '../../layouts';
import Calendly from 'pourmgmt/calendly';

export default (props) => (
  <Layout>
    <Row>
    <Col md={6} mdOffset={3}>
      <div className="about-content about-read" style={{textAlign: 'center', marginBottom: '30px'}}>
        <div style={{marginTop:'20px'}}><img src="https://d3v0px0pttie1i.cloudfront.net/uploads/user/avatar/4836107/7e2d2c13.jpg" style={{borderRadius: '50%', height: '150px', width: '150px'}}/></div>
        <h3 style={{marginTop: '30px'}}>Birk O'Halloran</h3>
        <div className="cart-table">
        <Table responsive>
            <tbody>
              <tr><a href="/appointments/virtual-tasting"><td style={{padding:'30px'}}>
                <h4>Virtual Tasting and Chat</h4>
                <p>Schedule a 20 minute tasting and chat with Birk O'Halloran. Catch him at the winery, vineyard or backyard garden. Where ever he is, he'll take a break and walk you through whatever Iconic wines you're drinking and give you insight into the wine-making life.</p>
              </td></a></tr>
              <tr><a href="/appointments/virtual-tour"><td style={{padding:'30px'}}>
                <h4>Winery Tour Tuesday</h4>
                <p>A private winery tour with Birk O'Halloran. Via a one on one video chat, see behind the curtain to where all the magic happens.</p>
              </td></a></tr>
            </tbody>
          </Table>
        </div>
      </div>
    </Col>
    </Row>
    </Layout>
)
